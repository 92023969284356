import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { css } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";
import { mobile, mobileLarge, tabletSmall } from "clutch/src/Style/style.mjs";
import { t } from "i18next";
import type { ElementOf } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import {
  GAME_FRIENDLY_SHORT_NAME_MAP,
  GAME_SHORT_NAMES,
} from "@/app/constants.mjs";
import ArticleList from "@/feature-contentful/ArticleList.jsx";
import { CONTENTFUL_TAGS } from "@/feature-contentful/constants.mjs";
import type { RelatedEntries } from "@/feature-contentful/models.mjs";
import { filterHiddenTags } from "@/feature-contentful/utils.mjs";
import Container from "@/shared/ContentContainer.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { findGameSymbol, useGameSymbol } from "@/util/game-route.mjs";
import { encodeBase64 } from "@/util/get-hash.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function HomepageDefault() {
  const { t } = useTranslation();
  const gameSymbol = useGameSymbol();
  const {
    contentful: { related, intro },
  } = useSnapshot(readState);
  const shortName = useMemo(() => GAME_SHORT_NAMES[gameSymbol], [gameSymbol]);
  const gameIntro = useMemo(() => intro[shortName], [intro, shortName]);
  const entriesByGame = useMemo(
    () =>
      related
        .filter((i) => i.tags.some((j) => j === CONTENTFUL_TAGS[shortName]))
        .sort((a, b) => b.createdAt - a.createdAt),
    [related, shortName],
  );
  const { topFold, tilesGrid, tilesList } = useMemo(() => {
    const result: {
      tilesGrid?: RelatedEntries;
      tilesList?: RelatedEntries;
      topFold?: ElementOf<RelatedEntries>;
    } = {};
    for (const entry of entriesByGame) {
      const tags = entry.tags;
      if (tags.some((i) => i === CONTENTFUL_TAGS.TILES_GRID)) {
        // @ts-ignore
        result.tilesGrid = result.tilesGrid?.concat([entry]) ?? [entry];
      }
      if (tags.some((i) => i === CONTENTFUL_TAGS.TILES_LIST)) {
        // @ts-ignore
        result.tilesList = result.tilesList?.concat([entry]) ?? [entry];
      }
      if (tags.some((i) => i === CONTENTFUL_TAGS.TOP_FOLD)) {
        // @ts-ignore
        result.topFold = entry;
      }
    }
    // Slice tiled articles
    for (const k of ["tilesList", "tilesGrid"]) {
      if (!result[k]) continue;
      result[k] = result[k as "tilesList"].slice(0, 4);
    }
    return result;
  }, [entriesByGame]);
  return (
    <Container className={cssHomepageDefault()}>
      {Boolean(gameIntro) && (
        <Card className="relative w-full" classNameOuter="intro">
          <div>
            <img
              src={gameIntro.bgUrl}
              alt={gameIntro.title}
              className="bg"
              loading="eager"
            />
            <h1 className="type-article-headline">{gameIntro.title}</h1>
            <p className="type-body2">{gameIntro.description}</p>
          </div>
        </Card>
      )}
      {Boolean(topFold) && (
        <Card className="relative w-full" classNameOuter="top-fold">
          <img
            src={topFold.imageUrl}
            alt={topFold.title}
            className="bg"
            loading="eager"
          />
          <div>
            <h2 className="type-article-headline">{topFold.title}</h2>
            <p className="type-body2">{topFold.excerpt}</p>
          </div>
          <Button as="a" href={`/${shortName}/article/${topFold.id}`}>
            {topFold.ctaText ?? t("contentful:readMore", "Read More")}
          </Button>
        </Card>
      )}
      {Boolean(tilesGrid) && (
        <>
          <h2 className="type-h5 tiles-grid-title">
            {t("contentful:title.trendingArticles", "Trending articles")}
          </h2>
          <div className="tiles-grid">
            <section>
              {tilesGrid.map((tile) => {
                return (
                  <a href={`/${shortName}/article/${tile.id}`} key={tile.id}>
                    <Card
                      classNameOuter="h-full w-full"
                      className="relative h-full w-full flex column gap-4 justify-between"
                    >
                      <img
                        src={tile.imageUrl}
                        alt="bg image"
                        className="bg"
                        loading="lazy"
                      />
                      <h3 className="type-article-headline">{tile.title}</h3>
                      <div className="flex column gap-1">
                        <p className="type-body2 shade1">
                          <Trans i18nKey="contentful:byAuthor">
                            By{" "}
                            <span className="type-subtitle--semi">
                              {tile.author ?? "Unknown"}
                            </span>
                          </Trans>{" "}
                          • <TimeAgo date={new Date(tile.createdAt)} />
                        </p>
                        {Boolean(tile.tags.length) && (
                          <div className="flex gap-2">
                            {tile.tags
                              .filter(filterHiddenTags)
                              .slice(0, 2)
                              .map((i) => (
                                <div
                                  className="br tag bg-shade10 color-shade2 type-mini"
                                  key={i}
                                >
                                  {i}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </Card>
                  </a>
                );
              })}
            </section>
          </div>
        </>
      )}
      {Boolean(tilesList) && (
        <>
          <h2 className="type-h5 tiles-list-title">
            {t("contentful:title.latestArticles", "Latest articles")}
          </h2>
          <ArticleList
            articles={tilesList.map((i) => ({
              id: i.id,
              coverImageUrl: i.imageUrl,
              title: i.title,
              excerpt: i.excerpt,
              createdAt: i.createdAt,
            }))}
            className="tiles-list"
          />
        </>
      )}
    </Container>
  );
}

const cssHomepageDefault = () =>
  css({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas:
      '"intro topfold" "tilesgridttitle tilesgridttitle" "tilesgrid tilesgrid" "tileslisttitle tileslisttitle" "tileslist tileslist" "ad ad"',
    [mobile]: {
      gridTemplateAreas:
        '"ad ad" "intro intro" "topfold topfold" "tilesgridttitle tilesgridttitle" "tilesgrid tilesgrid" "tileslisttitle tileslisttitle" "tileslist tileslist"',
    },
    [tabletSmall]: {
      gridTemplateAreas:
        '"intro intro" "topfold topfold" "tilesgridttitle tilesgridttitle" "tilesgrid tilesgrid" "tileslisttitle tileslisttitle" "tileslist tileslist"',
    },
    gap: "var(--sp-4)",
    "img.bg": {
      width: "100%",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: 0,
      zIndex: -100,
      filter: "opacity(0.2)",
    },
    ".ad": {
      gridArea: "ad",
      [mobile]: {
        display: "none",
      },
    },
    ".intro": {
      gridArea: "intro",
      ".card-contents": {
        alignItems: "flex-end",
      },
    },
    ".top-fold": {
      gridArea: "topfold",
      ".card-contents": {
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        gap: "var(--sp-4)",
      },
    },
    ".intro, .top-fold": {
      minHeight: "230px",
      display: "flex",
      ".card-contents": {
        display: "flex",
      },
    },
    ".tiles-grid": {
      gridArea: "tilesgrid",
      "> section": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        justifyItems: "stretch",
        gap: "var(--sp-4)",
        [mobileLarge]: {
          gridTemplateColumns: "1fr",
        },
        "> section": {
          display: "flex",
          minHeight: "150px",
        },
      },
    },
    ".tiles-list": {
      gridArea: "tileslist",
    },
    ".tiles-list-title": {
      gridArea: "tileslisttitle",
    },
    ".tiles-grid-title": {
      gridArea: "tilesgridttitle",
    },
    ".tag": {
      padding: "0 var(--sp-1_5)",
    },
    "> h2": {
      marginBottom: "calc(-1 * var(--sp-4))",
    },
  });

export function meta(): Meta {
  const gameSymbol = findGameSymbol();
  const shortName = GAME_SHORT_NAMES[gameSymbol];
  const {
    contentful: { intro },
  } = readState;
  const gameIntro = intro[shortName];
  // Set the title and description based off of the intro entry from Contentful
  // Every game should have an "intro" assigned tag
  if (
    typeof gameIntro?.title === "string" &&
    typeof gameIntro?.description === "string"
  ) {
    btoa("PEter");
    const encoded = encodeBase64(gameIntro.title);
    return {
      title: [
        `contentful:homepage.title.${shortName}.${encoded}`,
        gameIntro.title,
      ],
      description: [
        `contentful:homepage.title.${shortName}.${encoded}`,
        gameIntro.description,
      ],
    };
  }
  const friendlyName = t(...GAME_FRIENDLY_SHORT_NAME_MAP[gameSymbol]);
  return {
    title: [
      `contentful:homepage.title.${shortName}.default`,
      `${friendlyName} Tips, Guides & Strategies - Blitz`,
    ],
    description: [
      `contentful:homepage.title.${shortName}.default`,
      `Master ${friendlyName} with expert tips, in-depth guides, and winning strategies from Blitz.gg. Whether you're a beginner or a pro, we've got everything you need to level up your game!`,
    ],
  };
}
