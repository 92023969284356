import { CONTENTFUL_TAGS } from "@/feature-contentful/constants.mjs";

// These are special Blitz tags that you can attach to entries via the Contentful Admin Dashboard that
// can determine where the entry is visible on the homepage of your game
const IGNORE_TAGS = new Set([
  CONTENTFUL_TAGS.INTRO,
  CONTENTFUL_TAGS.TILES_GRID,
  CONTENTFUL_TAGS.TILES_LIST,
  CONTENTFUL_TAGS.TOP_FOLD,
]);

/**
 * Filter tags that are allowed to be visible to the user
 * @returns boolean
 */
export function filterHiddenTags(tag: string): boolean {
  return !IGNORE_TAGS.has(tag);
}
